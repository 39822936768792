@if (path | imgUrl: 'tenant' | async; as imgUrl) {
  <div class="h-full w-full">
    <img
      [src]="imgUrl"
      alt="Image"
      class="h-full w-full"
      [style]="{ 'object-fit': objectFit }"
      #myImage
    />
  </div>
} @else {
  <div class="w-full h-full flex justify-content-center align-items-center p-1">
    @if (showLoadingSpinner) {
      <app-progress-spinner [colour]="'white'"></app-progress-spinner>
    }
  </div>
}
