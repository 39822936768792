import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ProgressSpinnerComponent } from '../progress-spinner/progress-spinner.component';
import { UploadedImageUrlPipe } from '../uploaded-image/uploaded-image-url.pipe';

@Component({
  selector: 'app-logo-image',
  templateUrl: './logo-image.component.html',
  styleUrl: './logo-image.component.scss',
  standalone: true,
  imports: [ProgressSpinnerComponent, AsyncPipe, UploadedImageUrlPipe],
})
export class LogoImageComponent {
  @Input() path: string;
  @Input() objectFit = 'cover';
  @Input() showLoadingSpinner = true;
}
